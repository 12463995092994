.form-select {
  border-width: 0;
  background-color: #F4F4F4;

  transition: all .5s;

  border-radius: .5em;
  &:focus {
    background-color: darken(#F4F4F4, 10);
  }
}

.form-control {
  border-width: 0;
  background-color: #F4F4F4;
  transition: all .5s;
  border-radius: 0.5em;
}