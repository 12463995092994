.headings--section {
  color: $blue;
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;

  &.icon-android::after {
    content: url('../images/icon-android.svg');
    position: absolute;
    height: 100%;
    margin-left: 1em;
  }
}

.text-title {
  font-size: 3.125em;
  color: $blue;
  font-weight: 300;
  margin-bottom: .5em;
  text-transform: uppercase;
}

@include media-breakpoint-down(sm) {
  h2, .h2 {
    font-size: 2em;
  }
}