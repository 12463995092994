.wrap-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10000;
}

.navbar {
  padding: 0;
}

.header-padding {
  padding-top: 90px;
}

.header {
  position: relative;
  border-bottom: 1px solid #FFF;

  .small {
    font-size: 80%;
    font-weight: 400;
  }

  h1 {
    margin-bottom: 0;
  }
  a.nav-link {
    position: relative;
    color: #FFF;

    padding: .2em .9em !important;

    height: 90px;
    display: flex;
    align-items: center;
    
    &:not(.no-line)::before {
      content: "";

      position: absolute;
      left: 0;
      right: 0;
      bottom: 30%;
      width: 0;
      height: 1px;

      background-color: $primary;
      
      margin-left: auto;
      margin-right: auto;

      transition: all ease-out .5s;
    }

    svg {
      fill: white;
      transition: all ease-out .5s;
    }

    &:hover {
      color: #FFF !important;
      &::before {
        width: 100%;
        background-color: #FFF;
      }

      // svg {
      //   fill: $primary;
      // }
    }

    &:focus {
      color: #FFF !important;
    }
  }

  .dropdown > .dropdown-menu {
    overflow: hidden;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all ease-out .5s;
    top: 70%;
  }
  
  .dropdown:hover > .dropdown-menu {
    visibility: visible;
    opacity: 1;
  }

  &::after {
    content: "";
    @extend .bg-blue-gradient;
    
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.sticky {
    position: fixed;
    width: 100%;
    top: 0;
  }
}

.nav-lang {
  margin-left: 1em;
  display: flex;
  align-items: center;

  a.nav-link {
    border: 1px solid #fff;
    border-radius: 1em;
    transition: all ease-out .5s;

    height: auto;

    &:hover {
      border-color: $primary;
      &::before {
        width: 0%;
      }
    }
  }
}

.nav-forum {
  // background-image: linear-gradient(180deg, #499BE7 63%, #2A36C7 100%);
  background-color: #2A36C7;
}

.header__icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@include media-breakpoint-down(lg) {
  .dropdown>.dropdown-menu {
    height: 0;
    padding: 0;
    border: none;
  }

  .dropdown:hover>.dropdown-menu {
    height: 100%;
  }

  .navbar-offcanvas {
    background-color: $dark;
  }
  
  .header {
    a.nav-link {
      padding: 1em !important;
      height: auto;
    }
    .nav-lang {
      margin-left: 0;
      display: block;

      a.nav-link {
        padding: .2em 1em !important;
        display: inline-block;
      }
    }
    .nav-forum {
      background-image: none;
    }
  }
}