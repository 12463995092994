ul.list--doc {
  margin-top: 1em;

  li {
    margin-bottom: .75em;
  }

  a {
    color: $body-color;
    border-bottom: 1px solid $body-color;
  }
}

ol.list--rounded {
  list-style: none;
  padding-left: 40px;

  > li {
    margin: 0 0 0.5rem 0;
    position: relative;
    counter-increment: mycounter;

    &:before {
      content: counter(mycounter);
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      position: absolute;
      left: calc(-1 * 30px - 10px);
      line-height: 30px;
      width: 30px;
      height: 30px;
      top: 0;
      background: #F5AC00;
      border-radius: 50%;
      text-align: center;
    }
  }

  ul {
    list-style-type: disc;
  }
}