.border-dashed {
  border-style: dashed !important;
}

.border-rounded {
  border: 1px solid $body-color;
  border-radius: 1em;

  text-align: center;
}

.border-rounded-white {
  @extend .border-rounded;
  color: $white;
  border-color: $white;
}

.border-event {
  border: 1px solid $blue;
  font-weight: bolder;
  color: $blue;
  padding: .2em .5em;
  display: inline-block;
}