.accordion__unit {
  margin-bottom: 1em;
}

.link--collapse {
  @extend .bg-blue-gradient;
  display: block;
  color: $white;
  width: 100%;
  border-radius: 1em;

  padding: .8em 1em;

  &:hover {
    color: $white;
  }

  &:after {
    content: "\25B2";
    float: right;
    transition: transform .5s;
  }

  &.collapsed {
    background-color: #F4F4F4;
    background-image: none;

    color: $blue;

    &:after {
      transform: rotate(180deg);
    }
  }
}

.collapse__content {
  padding: 1em 2em;
}

.accordion--doc {
  @include media-breakpoint-up(lg) {
    border-right: 1px solid #D8D8D8;
  }
  @include media-breakpoint-down(md) {
    position: fixed;
    z-index: 10000;
    top: 62px;
    width: 100%;
    left: 0;
    padding: .5em;
    background-color: #fff;
    border-bottom: 1px solid #D8D8D8;
  }

  .navbar-toggler-icon {
    width: 1em;
    height: 1em;
  }
}

.link--doc {
  color: $body-color;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  .arrow {
    transform: rotate(-135deg);
    transition: transform .5s;
  }

  &.collapsed {
    .arrow {
      transform: rotate(45deg);
    }
  }
}

.collapse__content--doc {
  margin-top: 1em;
  margin-bottom: 1em;

  li {
    margin-bottom: 1em;
    a {
      color: $body-color;
      font-size: .9em;

      &.active {
        color: $primary;
        font-weight: bolder;
      }
    }
  }
}