@import

'abstracts/variables',
'abstracts/mixins',
'abstracts/fix',

'vendors/bootstrap',
'vendors/bootstrap.offcanvas',

'base/responsive',

'layout/header',
'layout/footer',
'layout/copyright',
'layout/chart',

'pages/index',
'pages/product',
'pages/download',
'pages/doc',

'components/link',
'components/hr',
'components/bg',
'components/image',
'components/icon',
'components/text',
'components/form',
'components/link',
'components/headings',
'components/tables',
'components/border',
'components/list',
'components/collapse',
'components/button',
'components/blockquote';


:lang(ja), .ja {
  font-family: "メイリオ",Meiryo,Osaka,"小塚ゴシック Pro H",KozGoPr6N-Heavy,KozGoPro-Heavy,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","MS PGothic",sans-serif;
}