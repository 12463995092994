.chart {
  position: relative;

  .chart__unit {
    position: absolute;
    display: flex;
    align-items: center;
    width: 80%;
    height: 5%;

    &.first {
      .chart__value {
        font-size: .8em;
        font-weight: lighter;

        @include media-breakpoint-down(sm) {
          font-size: .6em;
        }
      }
    }
  }

  .chart__line {
    display: inline-block;
    height: 100%;
  }

  .chart__line--orange {
    background-color: #FF7500;
    background-image: linear-gradient(269deg, #FF7500 0%, #FAC735 100%);
  }

  .chart__line--gray {
    background-color: #DBDBDB;
  }

  .chart__line--blue {
    background-color: #000B8C;
    background-image: linear-gradient(269deg, #000B8C 0%, #3595FA 100%);
  }

  .chart__line--red {
    background-color: #8C0000;
    background-image: linear-gradient(269deg, #8C0000 0%, #FA3535 100%);
  }
  
  .chart__value {
    display: inline-block;
    margin-left: .5em;
    color: #535353;

    font-weight: bolder;

    @include media-breakpoint-down(sm) {
      font-size: .7em;
    }
  }

  .chart__bg {
    max-width: 100%;
    height: auto;
  }

  .chart__percent {
    font-size: 1.5em;
    margin-left: 0.25rem;
    @include media-breakpoint-down(sm) {
      font-size: 0.8em;
      margin-left: 0.1em;
    }
  }
}

.chart--trend {
  position: relative;

  .chart__wrap {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  .chart__line {
    object-fit: cover;
    object-position: top left;

    max-width: 100%;
    height: 100%;

    &.first {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .chart__bg {
    max-width: 100%;
    height: auto;
  }
}