// .bg--review {
//   background-image: url(../images/bg-review.jpg);
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: 0 70%;
// }

.bg-blue-gradient {
  // background-image: linear-gradient(0deg, #000B8C 0%, #3595FA 100%);
  background-color: #000B8C;
}

.bg-angle-a-l {
  @include angle(after, false);
}

.bg-angle-a-r {
  @include angle(after, true);
}

.bg-angle-b-l {
  @include angle(before, false);
}

.bg-angle-b-r {
  @include angle(before, true);
}

.bg-angle-both-lr {
  @include angle(before, false);
  @include angle(after, true);
}

.bg-angle-both-rl {
  @include angle(before, true);
  @include angle(after, false);
}

%bg-cover-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-index {
  @extend %bg-cover-image;
  background-image: url("../images/bg-index.jpg");
  background-position: bottom center;
}