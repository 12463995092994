.table--sp {
  th {
    background-color: #F7F7F7;
    width: 30%;
  }
  th, td {
    border-top: 0;
    padding: 2em 3em;
    font-weight: normal;

    @media (max-width: 768px) {
      padding: 1em;
    }
  }

  td {
    word-break: break-all;
  }

  tr:not(:last-child) {
    th, td {
      border-bottom: 1px solid #979797;
    }
  }
}