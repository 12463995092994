.img-full {
  width: 100%;
  height: auto;
}

.wrap-img {
  width: 100%;
  height: 300px;

  margin-bottom: 2em;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center bottom;
  }

  @include media-breakpoint-down(md) {
    height: 200px;
  }
}

.wrap-img-bg {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  min-height: 10em;

  .content {
    position: relative;
    z-index: 10;
    align-self: center;

    padding: 0 20px;
    border-bottom: 1px solid #FFF;

    color: #FFF;
    font-size: calc(40/16)+rem;
    text-transform: uppercase;
    font-weight: lighter;
  }
}

.img-bg {
  position: absolute;
  object-fit: cover;
  object-position: center center;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-kv {
  object-fit: cover;
  width: 100%;
  height: 380px;
}

.img-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}