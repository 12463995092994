.download-list {
  border: 1px solid #DBDBDB;
  border-radius: 1em;
  margin-bottom: 1em;
}

.download-list__header {
  padding: 1em;

  > div {
    padding-top: .2em;
    padding-bottom: .2em;
  }

  .title {
    font-size: 1.2em;
  }

  .btn {
    border-radius: 0;
    padding: .1em .6em;
  }

  .state {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;

    .horizontal {
      position: absolute;
      background-color: $blue;
      width: 30px;
      height: 1px;
      left: 50%;
      margin-left: -15px;
      top: 50%;
      margin-top: -0.5px;

      transition: all 0.5s ease-in-out;
      transform: rotate(90deg);
      opacity: 0;
    }

    .vertical {
      position: absolute;
      background-color: $blue;
      width: 1px;
      height: 30px;
      left: 50%;
      margin-left: -0.5px;
      top: 50%;
      margin-top: -15px;

      transition: all 0.5s ease-in-out;
      transform: rotate(90deg);
    }

    &.collapsed {
      .vertical {
        transform: rotate(-90deg);
      }

      .horizontal {
        transform: rotate(-90deg);
        opacity: 1;
      }
    }
  }
}

.download-list__body {
  border-top: 1px solid #DBDBDB;
  padding: 1em;

  .content {
    word-break: break-word;
  }
}