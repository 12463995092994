.index_swiper-container, .index_desc-swiper-container {
  // pagination outside
  .swiper-wrapper {
    padding-bottom: 40px;
  }

  .swiper-pagination-bullet {
    width: 30px;
    height: 5px;
    border: 1px solid #000B8C;
    border-radius: 0;
    margin: 0 8px !important;

    background-color: transparent;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background-color: #000B8C;
    }
  }
}

.index_swiper-container {

  img {
    width: 100%;
    height: auto
  }

  a {
    position: relative;
    display: block;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      background-image: linear-gradient(90deg, rgba(0,0,0,0) 40%, rgba(0,0,0,0.9) 52%, rgba(0,0,0,0.9) 100%);
    }
  }

  @include media-breakpoint-down(md) {
    // pagination outside
    .swiper-wrapper {
      padding-bottom: 0;
    }
    
    .swiper-pagination {
      display: none;
    }

    img {
      object-fit: cover;
      object-position: left;
      height: 60vw;
    }

    a::after {
      display: none;
    }
  }
}

.index_desc-wrap {
  position: absolute;
  pointer-events: none;
  @include media-breakpoint-down(md) {
    position: relative;
  }
}

.index_desc-swiper-container {
  // background-image: linear-gradient(179deg, #000B8C 0%, #3595FA 100%);
  color: white;
  // top: -100px;
  // left: -100px;
  width: 100%;
  line-height: 2em;
  letter-spacing: 1px;

  .swiper-slide-active {
    pointer-events: none !important;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    padding: 20px;
  }

  ul {
    padding-left: 1em;
    > li {
      font-weight: 300;
      margin-bottom: .4em;
    }
  }

  .title {
    font-size: calc(42/16)+rem;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: 500;
  }

  .link {
    font-size: calc(22/16)+rem;
    display: block;
    color: white;
    position: relative;
    left: 55%;
    width: 42%;

    p:last-child {
      margin-bottom: 0 !important;
    }
    
    ul:last-child {
      margin-bottom: 0;
    }
  }

  .swiper-pagination {
    display: none;
  }

  @media screen and (max-width: 1440px){
    line-height: 1.5em;
    
    .title {
      font-size: calc(27.2/16)+rem;
      margin-bottom: 0.2em;
    }
    .link {
      // font-size: (18/16)+rem;
      font-size: inherit;
      left: 50%;
      width: 50%;
      padding: 1em;
    }

    ul {
      > li {
        margin-bottom: 0;
        line-height: 1.5em;
      }
    }
  }

  @include media-breakpoint-down(md) {
    // position: relative;
    // top: 0;
    // left: 0;
    // width: auto;
    .swiper-pagination {
      display: block;
    }

    .swiper-slide {
      background-color: #666;
      height: auto;
      align-self: stretch;
    }

    .link {
      font-size: inherit;
      width: 100%;
      left: 0;
    }
  }
}

.ind__unit,
.blog__unit {
  img {
    width: 100%;
    height: auto;
  }
  .title {
    margin-top: .5em;
    font-size: calc(24/16)+rem;
    font-weight: normal;
  }
  .desc {
    margin-bottom: 0;
  }
}

.blog__bg {
  position: absolute;
  z-index: -1;
  top: 200px;
  width: 100%;
  height: 100%;
  background-image: url("../images/index_blog_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}