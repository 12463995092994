blockquote {
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;

  // color: #cc4b87;
  // background-color: #F9F9F9;
  padding: 16px;
  font-size: 85%;

  line-height: 1.45;
  background-color: #f5f8fa;
  border-radius: 6px;
}