.copyright {
  background-color: $blue;
  text-align: center;
  color: $white;
  font-size: calc(12/16)+em;

  padding-top: 16px;
  padding-bottom: 16px;

  a {
    color: $white;
    border-bottom: 1px solid transparent;

    transition: border-color .5s;
    &:hover {
      border-bottom-color: #fff;
    }
  }
}