.visible-lg-up {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.visible-lg-down {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}


.visible-md-up {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.visible-md-down {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.visible-sm-up {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.visible-sm-down {
  @include media-breakpoint-up(md) {
    display: none;
  }
}