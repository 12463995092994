.doc__content {
  img {
    max-width: 100%;
    height: auto;
  }

  pre {
    padding: 1em;
    max-width: 100%;
    overflow: auto;
    font-family: "Courier 10 Pitch", Courier, monospace;
    border: 1px solid #eee;
    white-space: pre-line;
  }

  kbd,
  tt,
  var,
  code {
    overflow: auto;
    vertical-align: middle;
    text-indent: 0;
  }

  code {
    background-color: #eeeeee;
  }

  .entry-title {
    font-size: calc(37/16)+em;
    margin-bottom: 1em;
  }

  h2 {
    font-size: calc(30/16)+em;
    margin: 0 0 .5em;
    clear: both;
  }

  h3 {
    font-size: calc(26.85/16)+em;
    margin: 1em 0 .5em;
  }

  .counter-hierarchy.ez-toc-grey {
    background: #F9F9F9;
    border: 1px solid #AAA;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    display: table;
    margin-bottom: 1em;
    padding: 10px;
    position: relative;
    width: auto;
  }

  .ez-toc-title {
    font-weight: 500;
    font-size: calc(18/16)+em;
    margin-bottom: 0;
  }

  .ez-toc-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    a {
      color: $body-color;
      font-size: calc(14px/16px)+em;
    }

    ul {
      list-style-type: none;
      padding-left: 1em;
    }
  }

  .wp-block-embed {
    max-width: 360px;
    width: 100%;

    float: left;
    margin: 0 auto 1em auto;
  }
  .wp-block-embed__wrapper {
    position: relative;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.wp-block-file {
  margin-bottom: 1.5em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: .75em;
}

.wp-block-file__button {
  background: #32373c;
  border-radius: 2em;
  color: #fff;
  font-size: 13px;
  padding: .5em 1em;
  &:hover {
    opacity: .85;
  }
}