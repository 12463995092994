.product__unit {
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    width: 100%;
    height: auto;
    // min-height: 260px;

    object-fit: contain;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .title {
    font-size: calc(24/16)+rem;
  }

  .desc {
    min-height: 3em;
    margin-bottom: 2em;
    flex-grow: 1;
  }
}

.product__kv {
  position: relative;
  z-index: 100;
  pointer-events: none;
}

.product__nav {
  .active {
    border-bottom: 2px solid $primary;
  }

  .nav-link {
    text-transform: uppercase;
    color: $body-color;
    margin: 0.5rem 1rem;
    padding: 0 0 .2em 0;
  }
}

.wrap-gallery {
  .col {
    margin-bottom: 30px;
  }
}
.gallery_unit {
  border: 1px solid #DBDBDB;
  border-radius: 2em;
  display: block;
  img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: -1;
  }
}

.gallery__list {
  font-weight: lighter;
  li {
    margin-bottom: 1em;
  }
}

.float-img {
  @include media-breakpoint-up(lg) {
    position: absolute;
    width: 120% !important;
    transform: translateY(-50%);
  }
}

.benchmark__tab {
  background-color: #DBDBDB;
  .nav-link {
    padding: 1em 2em;
    font-size: calc(14/16)+em;
    color: $body-color;
    transition: all 0.5s;
  }
  .active {
    color: $white;
    background-color: $primary;
  }
}