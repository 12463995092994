a {
  transition: color .5s;
  text-decoration: none;
}

a.link-body {
  color: $body-color;
  img {
    transition: opacity .5s;
  }
  &:hover {
    img {
      opacity: .8;
    }
    color: $primary;
  }
}

a.link--same-height {
  display: block;
  position: relative;
  height: 100%;
  padding-bottom: 38px;

  img {
    flex-shrink: 0;
  }

  .text--ellipsis::after {
    flex: 1 1 auto;
  }

  .btn {
    position: absolute;
    bottom: 0;
  }
}