// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

$font-family-sans-serif: 'Roboto', "Microsoft JhengHei", "PingFang TC", "Hiragino Sans TC", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", -apple-system, sans-serif;

$font-family-base: $font-family-sans-serif;

$body-color: #535353;
$primary: #000B8C;
// $secondary: #C5B69E;

// $link-hover-color: #C5B69E;
$link-hover-decoration: none;

// $h1-font-size: 5em;
$h2-font-size: calc(50/16)+em;
// $h3-font-size: 3.75em;
$h4-font-size: 1.48em;
// $h5-font-size: 1.25em;
// $h6-font-size: 1em;

// $headings-font-weight: 300;
// $headings-color: #000B8C;

$navbar-dark-color: #FFF;
$navbar-dark-hover-color: $primary;

$blue: #000B8C;
$danger: #dc3545;
$dark: #343a40;
$theme-colors: (
  "primary": $primary,
  "blue": $blue,
  // "grey": #EDEDED,
  "grey": #d8d8d8,
  "grey-light": #F4F4F4,
  "orange": #FAB92B,
  "black": #000,
  "danger": $danger,
  "dark": $dark
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  50px: 50px,
  -50px: -50px,
  -70px: -70px,
  75px: 75px,
  100px: 100px,
  -100px: -100px,
  150px: 150px,
  -150px: -150px,
  200px: 200px,
  -200px: -200px,
);

$grid-gutter-width: 1.875rem;

$border-radius: 0;
$hr-opacity: 1;

$enable-smooth-scroll: false;

*:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
