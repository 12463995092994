.footer {
  border-top: 1px solid #D8D8D8;
  margin-bottom: 50px;

  .widget {
    margin-top: 50px;

    &:last-child {
      border-left: 1px solid #555;
    }
  }

  .widget__title {
    color: #000B8C;
    font-size: calc(18/16)+em;
    text-transform: uppercase;

    margin-bottom: 1em;
  }

  //ul
  .widget__menu {
    list-style-type: none;
    padding: 0;

    li {
      padding-top: .3em;
      padding-bottom: .3em;

      a {
        img {
          padding-right: .6em;
        }
        display: flex;
        > * {
          align-self: center;
        }
      }
    }
  }

  a {
    color: #535353;
    &:hover {
      color: $primary;
    }
  }
}

.btn--top {
  position: fixed;
  z-index: 9999;
  opacity: 0;
  right: 20px;
  bottom: 60px;
  border: 2px solid $blue;
  padding: 5px 10px 10px;
  cursor: pointer;

  transition: all ease-out .5s;

  svg {
    stroke: $blue;
    transition: all ease-out .5s;
  }

  &:hover {
    background-color: $blue;
    svg {
      stroke: #FFF;
    }
  }
}